import React, { useEffect, useRef } from 'react';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const Travel = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();
  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <section id="places">
      <h2 className="h1" ref={revealContainer}>

      </h2>

      <video controls autoplay
        align="center"
        title="Deepcept AI"
        src="intro.mp4"
        width="100%"
        height="480"
      ></video>

    </section>
  );
};

export default Travel;
