import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Logo</title>
    <g>
      <g id="D" transform="translate(28.000000, 60.000000)">
        <path
          d="M 24.109375 -7.125 L 8.71875 -7.125 L 5.671875 0 L -0.21875 0 L 13.640625 -30.796875 L 19.265625 -30.796875 L 33.171875 0 L 27.1875 0 Z M 22.21875 -11.609375 L 16.40625 -25.078125 L 10.640625 -11.609375 Z M 22.21875 -11.609375"
          fill="currentColor"
        />
      </g>
      <g id="D" transform="translate(58.000000, 60.000000)">
        <path
          d="M 4.140625 -30.796875 L 9.859375 -30.796875 L 9.859375 0 L 4.140625 0 Z M 4.140625 -30.796875"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLogo;
