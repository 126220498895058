import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';
import Typed from 'react-typed';
// import { email } from '@config';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 0;

  @media (max-width: 480px) and (min-height: 700px) {
    padding-bottom: 10vh;
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h2 {
    margin-top: 10px;
    color: var(--white);
    line-height: 0.9;
  }

  h3 {
    margin-top: 10px;
    color: var(--white);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
    color: var(--white);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
    color: white;
  }
  // .video{
  //   position:relative;
  //   max-width:900px;
  //   outline: none;
  // }
  // @media (max-width:768px)
  // {
  //   .video{
  //     max-width:90%;
  //   }
  // }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1></h1>;
  const two = <h2 className="big-heading">Deepcept AI</h2>;
  const three = (
    <div>
      <h3 className="big-heading">
        Complex AI integration {' '}
        <Typed
          loop
          typeSpeed={100}
          backSpeed={70}
          strings={['made Seamless.', 'made Reliable.', 'made Robust.', 'made Effortless.']}
          shuffle={false}
          backDelay={400}
          fadeOut={false}
          fadeOutDelay={100}
          loopCount={100000}
          showCursor
          cursorChar=""
        />
      </h3>
    </div>
  );
  const four = (
    <>
      <p>
        Hyper-scalable and decentralised plug and play AIOT platforms that leverage existing infrastructure.
      </p>
    </>
  );

  /*
  const five = (
    <a
      className="email-link"
      href="https://www.newline.co/courses/build-a-spotify-connected-app"
      target="_blank"
      rel="noreferrer">
      Button for recruiter!
    </a>
  );
  */

  // const six = (
  //   <div>
  //     <iframe
  //       align="center"
  //       title="GG Maps"
  //       src="intro.mp4"
  //       width="200%"
  //       height="200"
  //     ></iframe>
  //   </div>

  // )

  const five = (
    <Link className="email-link" to="/nerds">
      Explore the world of Deepcept AI
    </Link>
  );



  const items = [one, two, three, four, five];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
